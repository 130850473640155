import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { store } from '../../../classes/ReduxStore';
import { Provider, connect } from 'react-redux';
import { ApiClass } from '../../../classes/Api';
import { StoreCartData } from '../../../actions';


function BigCartSmallTable(props){

	return(
		<Fragment>

   <tbody>
      <tr className="cart-subtotal">
         <th>Kwota</th>
         <td data-title="Subtotal"><span className="woocommerce-Price-amount amount">
         {props.data.CartTotal} zł</span></td>
      </tr>
      <tr className="cart-subtotal">
         <th>Wysyłka</th>
         <td data-title="Subtotal"><span className="woocommerce-Price-amount amount">20.00 zł</span></td>
      </tr>
      <tr className="order-total">
         <th>Suma</th>
         <td data-title="Total"><strong><span className="woocommerce-Price-amount amount">
         {parseFloat(props.data.CartTotal) + 20} zł</span></strong> </td>
      </tr>
   </tbody>
		   
		</Fragment>
	)

}

const mapStateToProps = state => {
  return {
    data: state.CartData,
  }
}

const BigCartSmallTableWrapped = connect(mapStateToProps)(BigCartSmallTable);

if (document.getElementById('smallTable')) {

    ReactDOM.render(
    	<Provider store={store}>
    	<BigCartSmallTableWrapped />
    	</Provider>, document.getElementById("smallTable"));
}

