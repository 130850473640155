import React, { Fragment, useEffect } from 'react';

export function OrderTotalView(props){

  const totalPriсe = props.totalPrice;
  const deliveryPrice = props.deliveryPrice;  

	return(
    <Fragment>
		<tr className="order-total">
           <th>Wysyłka</th>
           <td data-title="Total"><span className="woocommerce-Price-amount amount">{deliveryPrice} zł</span> </td>
    </tr>
    <tr className="order-total">
           <th>Suma</th>
           <td data-title="Total"><span className="woocommerce-Price-amount amount">
           { totalPriсe } zł</span> </td>
    </tr>
    </Fragment>
	)
}