const CurrentCategoryId = (state = 777, action) => {
	switch (action.type) {

		case 'CURRENT_CATEGORY_ID':
			return action.int;

		default:
			return state;
	}
};


export default CurrentCategoryId;