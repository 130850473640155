class OrderModel
{

deliveryPriceForCountry = async (data) => {
    const url = '/api/cart/deliveryPriceForCountry';
    const response = await axios.post(url, data);
    return response;
  }

}

export const Order = new OrderModel();