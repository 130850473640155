const CurrentProductsSearchString = (state = '', action) => {
	switch (action.type) {

		case 'CURRENT_PRODUCTS_SEARCH_STRING':
			return action.string;

		default:
			return state;
	}
};


export default CurrentProductsSearchString;