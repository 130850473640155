import React, { Fragment } from 'react';


function ArchiveHeaderView(props){

	const total = props.total;
	
	return(
		<Fragment>
		<div className="archive-header">
           <span>Znaleziono produktów: {total}</span>
        </div>

        	<button id="mob-categories-open" className="text-unset show-more-425px mw-7rem btn-default m-5">Kategorie</button>
           <button id="mob-filter-open" className="text-unset mw-7rem btn-default m-5 float-right">Filtry</button>

		</Fragment>
	)

}

export default ArchiveHeaderView;