const ProductsPerCategory = (state = 0, action) => {
	switch (action.type) {

		case 'PRODUCTS':
			return action.json;

		default:
			return state;
	}
};


export default ProductsPerCategory;