import React, { Fragment } from 'react';

function MobileFilterViewTest(props){

	const filters = props.filters;
	const filterValues = props.filterValues;
	const str_slug = props.str_slug;
	const checkFilterValue = props.checkFilterValue;
	const clearFilters = props.clearFilters;
	const checkboxesCount = props.checkboxesCount == 0 ? '': '('+props.checkboxesCount+')';
	const total = props.total == 0 ? 'Nie ma wyników': 'Pokaż wyniki ('+props.total+')';

	return(
		<Fragment>
		
		<div className="mob-filter-wrapp">

			<div className="mob-filter-header bg-light d-flex justify-content-between">
				<div className="mob-filter-clear-wrap font-size-small">
					<a onClick={clearFilters} href="##">Wyczyść{checkboxesCount}</a>
				</div>
				<div className="mob-filter-header-wrap">
					Filtrowanie/Sortowanie
				</div>
				<div className="mob-filter-close">
					<span className="lnr lnr-cross"></span>
				</div>
			</div>

			{/*<div className="sort-wrapper">
				<span className="text-bolder">Sortuj</span>
			</div>*/}

			<div className="filter-wrapper">
				{/*<span className="text-bolder">Filtruj</span>*/}
				<ul className="product-filters">
		            {filters.map((atr, i) =>(
		            <li key={i}>
		              <span>{atr.attribute}:</span>
		               <ul className="attr-values">
		              {Object.keys(filterValues).length && filterValues[atr.attribute].map((value, i) =>(
		              	value[1] > 0 && 
		               <li key={i} className="custom-control custom-checkbox w-100">
		                  <input id={str_slug(atr.attribute)+'-'+str_slug(value[0])}
		                  name={str_slug(atr.attribute)}
		                  value={str_slug(value[0])}
		                  onChange={checkFilterValue}
		                  className="custom-control-input" type="checkbox"
		                   />
		                  <label htmlFor={str_slug(atr.attribute)+'-'+str_slug(value[0])}
		                   className="custom-control-label">
		             		{value[0].substring(0,25)} <span className="pdt-count">({value[1]})</span>
		                  </label>  
		               </li>
		              ))}
		               </ul>
		            </li>
		           ))}
		         </ul>
			</div>

		</div>
		<div className="mob-filter-button-wrapper">
			<button id="show-filter-results" className="text-larger btn-default w-75">{total}</button>
		</div>
		
		</Fragment>
	)

}

export default MobileFilterViewTest;