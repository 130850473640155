import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { OrderTotalView } from '../Views/OrderTotalView';
import { Order } from '../Models/OrderModel';

///=============================
function OrderTotal(props){

	
	const[deliveryPrice, setDeliveryPrice] = useState(20);
	const totalPrice = parseInt(props.cartTotal) + deliveryPrice;

	const select = document.querySelector('select[name=country]');

	select.addEventListener('change', (event) => {
	  const country = event.target.value;
	  Order.deliveryPriceForCountry({country:country}).then(response => {
	  	setDeliveryPrice(response.data)
	  })
	});


	return(<OrderTotalView
	totalPrice={totalPrice}
	deliveryPrice={deliveryPrice}
	/>)
}

if (document.getElementById('order-total')) {
  	const cartTotal = document.getElementById('order-total').getAttribute('data-total-price');
    ReactDOM.render(
    <OrderTotal cartTotal={cartTotal} />, document.getElementById("order-total"));
}
///=============================