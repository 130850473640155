import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { store } from '../../../classes/ReduxStore';
import { Provider, connect } from 'react-redux';
import { ApiClass } from '../../../classes/Api';
import { StoreCartData } from '../../../actions';


function BigCartForm(props){

	const [state, setState] = useState({
      CartCount: '',
      CartTotal: ''
    });

    const changeQty = (e) => {
       const rowId = e.target.getAttribute('data-id');
       const newQty = e.target.value;
       if(newQty > 0)
       {
         ApiClass.changeQty({rowId:rowId,newQty:newQty}).then(response => {
           console.log(response)

           ApiClass.GetCartData().then(data => {
            store.dispatch(StoreCartData(data));
          })

         })
       }
       
    }

    const deleteItem = (e) => {
      e.preventDefault();
      const rowId = e.target.getAttribute('data-id');

      ApiClass.deleteItem({rowId:rowId}).then(response => {
           console.log(response)

           ApiClass.GetCartData().then(data => {
            store.dispatch(StoreCartData(data));
          })

      })

    }

    const getVariants = ( $object ) => {
      //filter options from slug and images
      const Variants = Object.entries( $object ).filter(item => item[0] != 'slug' && item[0] != 'image');
      return Variants;
    }

    if(props.data.content && props.data.content.length == 0){
      return (
        <Fragment>
        <div className="text-center">
        <h2>Twój koszyk jest pusty</h2>
        <p>Czas zrobić zakupy...</p>
        </div>
        </Fragment>
       )
    }

	return(
		<Fragment>

     <table className="shop_table cart" cellSpacing="0">
      <thead>
         <tr>
            <th className="product-remove">&nbsp;</th>
            <th className="product-thumbnail">&nbsp;</th>
            <th className="product-name">Produkt</th>
            <th className="product-price">Cena</th>
            <th className="product-quantity">Ilość</th>
            <th className="product-subtotal">Cena</th>
         </tr>
      </thead>
      <tbody>
         {props.data.content && Object.entries(props.data.content).map((pdt, i) => ( 
         <tr key={i} className="woocommerce-cart-form__cart-item cart_item">
            <td className="product-remove">
               <a data-id={pdt[1].rowId} href="#" onClick={deleteItem} className="remove">×</a>
            </td>
            <td className="product-thumbnail">
               <a href={'/produkt/'+pdt[1].options.slug}>
                 <img src={pdt[1].options.image} alt="" />
               </a>
            </td>
            <td className="product-name">
               <a title={pdt[1].name} href={'/produkt/'+pdt[1].options.slug}>
               {pdt[1].name}</a> <small>({getVariants(pdt[1].options)})</small>
            </td>
            <td className="product-price" data-title="Price">
               <span className="woocommerce-Price-amount amount">
               {pdt[1].price} zł</span>
            </td>
            <td className="product-quantity" data-title="Quantity">
               <div className="quantity">
                  <input type="number" data-id={pdt[1].rowId} className="input-text qty text" step="1"
                   min="0" onChange={changeQty}
                  value={pdt[1].qty} />
               </div>
            </td>
            <td className="product-subtotal" data-title="Total">
               <span className="woocommerce-Price-amount amount">
               { pdt[1].qty * pdt[1].price } zł</span>
            </td>
         </tr>
         ))}

      </tbody>
   </table>
		   
		</Fragment>
	)

}

const mapStateToProps = state => {
  return {
    data: state.CartData,
  }
}

const BigCartFormWrapped = connect(mapStateToProps)(BigCartForm);

if (document.getElementById('bigcartForm')) {

    ReactDOM.render(
    	<Provider store={store}>
    	<BigCartFormWrapped />
    	</Provider>, document.getElementById("bigcartForm"));
}

