const IsLoadingReducer = (state = 1, action) => {
	switch (action.type) {

		case 'IS_LOADING':
			return action.boolean;

		default:
			return state;
	}
};


export default IsLoadingReducer;