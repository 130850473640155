import React from 'react';


function ProductPaginator(props){

	const current_page = props.current_page;
	const last_page = props.last_page;
	const prev_page_nr = props.prev_page_nr;
	const next_page_nr = props.next_page_nr;
	

	const Paginate = (e) => {
		e.preventDefault();
		const pageNr = e.target.getAttribute('data-nr');
		if(pageNr == null){return;}

		if(pageNr !== null && window.location.search.includes('?szukaj=') 
			&& window.location.search.includes('filters:')){
			const divider = '&';
			const searchQuery = window.location.search.split('szukaj=')[1].split('&')[0];
			const filterQuery = window.location.search.split('filters:')[1].split('&')[0];
			const newUrl = '?szukaj='+searchQuery+divider+'filters:'+filterQuery+divider+'page='+pageNr;
			props.setSearch(newUrl);
			return
		}

		if(pageNr !== null && window.location.search.includes('?szukaj=')){
			const divider = '&';
			const searchQuery = window.location.search.split('szukaj=')[1].split('&')[0];
			const newUrl = '?szukaj='+searchQuery+divider+'page='+pageNr;
			props.setSearch(newUrl);
			return
		}

		if(pageNr !== null && window.location.search.includes('filters:')){
			const divider = '&';
			const filterQuery = window.location.search.split('filters:')[1].split('&')[0];
			const newUrl = '?filters:'+filterQuery+divider+'page='+pageNr;
			props.setSearch(newUrl);
			return
		}

		props.setSearch('?page='+pageNr);
	}

	if(last_page == 1)
	{
		return '';
	}
	
	return (
		<nav>
			<ul className="pagination d-flex justify-content-center">

				<li className="page-item">
					<a className="page-link" onClick={Paginate}
					href="##"
					rel="prev" aria-label="« Previous">
					<i data-nr={prev_page_nr} className="lnr lnr-arrow-left"></i>
					</a>
				</li>

				<li className="page-item">
					{current_page}/{last_page}
				</li>

				<li className="page-item">
					<a className="page-link" onClick={Paginate} 
					href="##" rel="next" aria-label="Next »">
					<i data-nr={next_page_nr} className="lnr lnr-arrow-right"></i>
					</a>
				</li>

				{/*props.pages && props.pages.map((nr, i) =>(
				<li key={i} className={'page-item' + ((props.current_page == nr) ? ' active' : '')}>
					<a className="page-link"
					onClick={Paginate} data-nr={nr}
					href={props.url+'?page='+nr}>{nr}</a>
				</li>)
			    )*/}

			</ul>
		</nav>
		)
}


export default ProductPaginator;