class ProductModel
{

warehouse = async (data) => {
    const url = '/api/single-product/warehouse';
    const response = await axios.post(url, data);
    return response;
  }

getShowProductPrices = async() => {
    const url = '/api/ecommerce/show-product-prices';
    const response = await axios(url);
    return response.data;
  }

}

export const Product = new ProductModel();