import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { store } from '../../../classes/ReduxStore';
import { Provider, connect } from 'react-redux';
import { ApiClass } from '../../../classes/Api';
import { StoreCartData } from '../../../actions';
import WishListController from '@/components/frontend/Controllers/WishListController';


function MiniCart(props){

	const [state, setState] = useState({
      CartCount: '',
      CartTotal: ''
    });

    useEffect(() =>{
    	ApiClass.GetCartData().then(data => {
    		store.dispatch(StoreCartData(data));
    	})
    }, [])


   const deleteItem = (e) => {
      e.preventDefault();
      const rowId = e.target.getAttribute('data-id');

      ApiClass.deleteItem({rowId:rowId}).then(response => {
           console.log(response)

           ApiClass.GetCartData().then(data => {
            store.dispatch(StoreCartData(data));
          })

      })

    }



  //show empty cart
  let EmptyMessageOrTotal =
   <Fragment>
   <p className="woocommerce-mini-cart__total total">
      <strong>Razem:</strong>
        <span className="amount">{props.data.CartTotal} zł</span>
      </p>
        <span className="sm-cart-btn-wrap">
          <p className="woocommerce-mini-cart__buttons buttons">
            <a href="/koszyk" className="button wc-forward">Do kosza</a>
            <a href="/kasa" className="button checkout wc-forward">Do kasy</a>
          </p>
        </span>
    </Fragment>;

  if(props.data.content && props.data.content.length == 0){
      EmptyMessageOrTotal = <div className="widget_shopping_cart_content">
                              <p className="woocommerce-mini-cart__empty-message">Koszyk jest pusty.</p>
                                </div>;
  }

	return(
		<Fragment>

			<ul id="site-header-cart" className="site-header-cart">
        <WishListController />
  			<li className="cart">
      		<a href="/koszyk" className="cart-contents">
        			<span className="sm-cart-icon-wrap">
        				<span className="icon">
                  <span className="lnr lnr-cart"></span>
                </span>
        				<span className={"sm-cart-count"+ (props.data.CartCount == 0 ? ' d-none': '')}>
                {props.data.CartCount}</span>
        			</span>
        		<span className="sm-cart-wrap">
        			<span className="sm-cart-text">Koszyk</span>
        			<span className="sm-cart-amount">{props.data.CartTotal} zł</span>
        		</span>
      		</a>
        </li>

  			<li>
    		
    		<div className="widget woocommerce widget_shopping_cart">
 
       <div className="widget_shopping_cart_content">

  <ul className="woocommerce-mini-cart cart_list product_list_widget ">

          {props.data.content && Object.entries(props.data.content).map((pdt, i) => ( 
              <li key={i} className="woocommerce-mini-cart-item mini_cart_item">  
              <div className="minicart-item-thumb">
                <a href={'/produkt/'+pdt[1].options.slug}>
                  <img width="300" height="300"
                  src={pdt[1].options.image}
                  className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail minicart-thumbnail"
                  alt="" />
                </a>
              </div>
              <div className="minicart-item-content">
                <h4 className="item-title">
                  <a title="title" href={'/produkt/'+pdt[1].options.slug}>
                  {pdt[1].name}</a>
                </h4>
                
              <span className="quantity">{pdt[1].qty} × <span className="amount">
                {pdt[1].price} zł
              	</span>
              </span>
              </div>
              
                        
            <a data-id={pdt[1].rowId} href="#" onClick={deleteItem} className="remove">×</a>           
          </li>

          ))}
            </ul>

          {EmptyMessageOrTotal}    

</div>


          </div>
        </li>
  		</ul>
		   
		</Fragment>
	)

}

const mapStateToProps = state => {
  return {
    data: state.CartData,
  }
}

const MiniCartWrapped = connect(mapStateToProps)(MiniCart);

if (document.getElementById('shoppingcart')) {

    ReactDOM.render(
    	<Provider store={store}>
    	<MiniCartWrapped />
    	</Provider>, document.getElementById("shoppingcart"));
}

