import React, { Fragment } from 'react';
import { ProductItem } from './ProductItem';

function Products(props){

	//const data = props.products;
	const { products:data, showProductPrices } = props;

	return (
		<Fragment>
			{data.map(prod =>
			    <ProductItem
				    key={prod.id}
				    id={prod.id}
				    slug={prod.slug}
				    title={prod.title}
				    image={prod.image}
				    min_price={prod.min_price}
				    max_price={prod.max_price}
				    showProductPrices={showProductPrices}
		     	/>

		     )}
		</Fragment>
	)
}


export default Products;