import React, { useState, useEffect } from 'react';

export function ProductItem(props)
{	
	const min_price = parseFloat(props.min_price);
	const max_price = parseFloat(props.max_price);
	const showProductPrices = props.showProductPrices;
	
	const price = () => {
		if(showProductPrices == false){
			return '--';
		}
		else if(min_price == 0 && max_price == 0){
			return '--';
		} else if(min_price == max_price){
			return min_price;
		} else if(min_price < max_price){
			return min_price+'-'+max_price;
		} else {
			return '--';
		}
	}


	return(
		<li className="product type-product view-line">
	        <div className="sml-product-image-wrapp">	
	        <div className="sml-add-to-wishlist-wrap">

	                <div className="sml-quick-view-wrapp">
	                    <a href={'/produkt/'+props.slug} title={'Kup z dostawą '+props.title}
	                    className="link-quickview yith-wcqv-button">
	                        <span>Zobacz</span>
	                    </a>
	                </div>

	        </div>
	        <div className="sml-product-wrapp">
		        <a href={'/produkt/'+props.slug} title={'Kup z dostawą '+props.title} >
		        	<img alt={props.title} title={'Kup z dostawą '+props.title} src={'/storage/'+props.image} />
		        </a>
	        </div>
	        </div>
	        <div className="sml-product-title-wrapp">
	        <h2 className="woocommerce-loop-product__title">
	            <a href={'/produkt/'+props.slug} title={'Kup z dostawą '+props.title}>
	            {props.title}</a>
	        </h2>

	        <div className="sml-price-wrap">
	        <span className="price">
	        <span className="amount">
	        {price()} zł</span>
	        </span>


	        <div className="sml-add-to-cart-wrap">
	                <a href={'/produkt/'+props.slug} title={'Kup z dostawą '+props.title}
	                 className="button add_to_cart_button">
	                Zobacz
	                </a>
	        </div>
	            </div>
	        </div>
	     </li>
	)
}
