const DatabaseCustomers = (state = 0, action) => {
	switch (action.type) {

		case 'DATABASE_CUSTOMERS':
			return action.array;

		default:
			return state;
	}
};


export default DatabaseCustomers;