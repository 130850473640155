import React, { Fragment, useState, useEffect } from 'react';
import { WishList } from '../../Models/WishList';
import { WithoutPriceNotSubscribedView } from './WithoutPriceNotSubscribedView';
import { WithoutPriceSubscribedView } from './WithoutPriceSubscribedView';

export function WithoutPriceView(props){
	
	const[state, setState] = useState({
		email: '',
		products_ids: [props.state.productId]
	});


	const handleChange = e => {
		e.persist();
		setState(prevState =>({
			...prevState,
			[e.target.name] : e.target.value
		}))
	}
	const subscribeToProduct = e => {
		e.preventDefault();
		if(state.email.length == 0){
			return;
		}
		WishList.subscribe(state).then(message => {
			setIsSubscribed(message)
		})
	}

	///check if user is subscribed for the product
	const[isSubscribed, setIsSubscribed] = useState('subscribed');
	useEffect(() => {
		WishList.isSubscribed(state).then(response => {
			setIsSubscribed(response.message_data)
			if(response.email){
				setState(prevState => ({
					...prevState,
					email: response.email
				}));
			}
		})
	}, [])

	const unsubscribeProduct = e => {
		e.preventDefault();
		WishList.unSubscribe(state).then(response => {
			setIsSubscribed(response.message_data)
		})
	}

	return(
		(isSubscribed == 'not_subscribed')?
		(<WithoutPriceNotSubscribedView
			handleChange={handleChange}
			subscribeToProduct={subscribeToProduct}
			state={state}
		/>)
		:
		(<WithoutPriceSubscribedView
			handleChange={handleChange}
			unsubscribeProduct={unsubscribeProduct}
			state={state}
		/>)
	)
}