export const StoreThisProductAttributes = (json) =>{
	return {
		type: 'ATTRIBUTES',
		json: json
	}
}

export const StoreAllProductAttributes = (json) =>{
	return {
		type: 'ATTRIBUTES_ALL',
		json: json
	}
}

export const StoreThisProductVariants = (json) =>{
	return {
		type: 'VARIANTS',
		json: json
	}
}

export const StoreCategoryProducts = (json) => {
	return {
		type: 'PRODUCTS',
		json: json
	}
}

export const StoreFrontEndThisProductAttributes = (json) =>{
	return {
		type: 'ATTRIBUTES',
		json: json
	}
}

export const UpdateUrl = (json) =>{
	return {
		type: 'URL',
		json: json
	}
}

export const StoreCartData = (json) =>{
	return {
		type: 'CARTDATA',
		json: json
	}
}
export const IsLoadingUpdate = (boolean) =>{
	return {
		type: 'IS_LOADING',
		boolean: boolean
	}
}
export const ActualFilters = (json) =>{
	return {
		type: 'ActualFilters',
		json: json
	}
}
export const CheckboxesCount = (number) => {
		return {
			type: 'COUNT',
			number: number
		}
}
export const SaveWishList = (json) => {
	return {
		type: 'WISHLIST',
		json: json
	}
}

export const SetDatabaseCustomers = (array) => {
	return {
		type: 'DATABASE_CUSTOMERS',
		array: array
	}
}

export const SetDistributorsList = (array) => {
	return {
		type: 'DISTRIBUTORS_LIST',
		array: array
	}
}

export const SetProductsList = (array) => {
	return {
		type: 'PRODUCTS_LIST',
		array: array
	}
}

export const SetSingleProductInfo = (json) => {
	return {
		type: 'SINGLE_PRODUCT_INFO',
		json: json
	}
}

export const SetCurrentProductCategoryId = (int) => {
	return {
		type: 'CURRENT_CATEGORY_ID',
		int: int
	}
}

export const SetCurrentProductsSearchString = (string) => {
	return {
		type: 'CURRENT_PRODUCTS_SEARCH_STRING',
		string: string
	}
}

export const SetInitProductsState = (boolean) => {
	return {
		type: 'INIT_PRODUCTS_STATE',
		boolean: boolean
	}
}

export const StoreProductCategories = (array) => {
	return {
		type: 'PRODUCT_CATEGORIES',
		array: array
	}
}