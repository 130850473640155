class Api
{

  FrontEndThisProductAttributes = async (data) => {
     
            const url = '/api/singleproduct/FrontEndThisProductAttributes';

            const {data: {message}, status} = await axios.post(url, data);

              //console.log(Posts);
            return message;

      }



  getProducts = async (data) => {
        const url = '/api/getProducts';
        const {data: {message}, status} = await axios.post(url, data);
          //console.log(Posts);
        return message;

  }


      getAllValuesForAllAttributesNames = async (data) => {

            const url = '/api/getAllValuesForAllAttributesNames';

            const {data: {message}, status} = await axios.post(url, data);

              //console.log(Posts);
            return message;

      }


      filter = async (data) => {

            const url = '/api/filter';

            const response = await axios.post(url, data);

              //console.log(Posts);
            return response.data;

      }

      addToCart = async (data) => {

            const url = '/api/addToCart';

            const {data: {message}, status} = await axios.post(url, data);

              //console.log(Posts);
            return message;

      }

      GetCartData = async () => {

            const url = '/api/GetCartData';

            const data = await axios(url);

            return data.data;

      }

      changeQty = async (data) => {

            const url = '/api/changeQty';

            const {data: {message}, status} = await axios.post(url, data);

            return message;

      }

      deleteItem = async (data) => {

            const url = '/api/deleteItem';

            const {data: {message}, status} = await axios.post(url, data);

            return message;

      }


      GetRelativeFilters = async (data) => {

            const url = '/api/GetRelativeFilters';

            const {data: {message}, status} = await axios.post(url, data);

            return message;

      }

      VariantsForProduct = async (data) => {  
            const url = '/api/products/VariantsForProduct';
            const {data: {message}, status} = await axios.post(url, data, this.headers);
              //console.log(Posts);
            return message;
      }

}

export const ApiClass = new Api();