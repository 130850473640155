import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '@/classes/ReduxStore';
import { connect } from 'react-redux';
import ArchiveHeaderView from '../View/ArchiveHeaderView';


function ArchiveHeader(props){

	const [state, setState] = useState({
      total: ''
    });

	useEffect(() => {
	    setState({
	    	total: props.ProductsPerCategory.total,
	    })
	}, [props]);
	
	return(
		<ArchiveHeaderView total={state.total} />
	)

}


const mapStateToProps = state => {
  return {
    ProductsPerCategory: state.ProductsPerCategory,
  }
}

const ArchiveHeaderWrapped = connect(mapStateToProps)(ArchiveHeader);

if (document.getElementById('ArchiveHeader')) {

	const props = document.getElementById('ArchiveHeader').getAttribute('data-props');

    ReactDOM.render(
    	<Provider store={store}>
    	<ArchiveHeaderWrapped data={props} />
    	</Provider>, document.getElementById("ArchiveHeader"));
}

