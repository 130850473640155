/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/frontend/Controllers/SingleProductController');
require('./components/frontend/category/Controller/CategoryController');
require('./components/frontend/category/Controller/ArchiveHeader');

require('./components/frontend/filter/Controller/FilterControllerTest');

require('./components/frontend/cart/MiniCart');
require('./components/frontend/cart/BigCartSmallTable');
require('./components/frontend/cart/BigCartForm');
require('./components/frontend/cart/Controller/SiteHeaderCart');

require('./components/frontend/Views/WishListView');
require('./components/frontend/Controllers/AnalyticsController');

///cart
require('./components/frontend/Controllers/OrderController');