const ProductsList = (state = [], action) => {
	switch (action.type) {

		case 'PRODUCTS_LIST':
			return action.array;

		default:
			return state;
	}
};


export default ProductsList;