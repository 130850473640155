import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { store } from '../../../classes/ReduxStore';

import { ApiClass } from '../../../classes/Api';
import { StoreThisProductVariants } from '../../../actions';
import { StoreFrontEndThisProductAttributes } from '../../../actions';
import { StoreCartData } from '../../../actions';
import { addToWishList } from '../Controllers/WishListController';

import { VariantsItemView } from '../Views/SingleProduct/VariantsItemView';
import { VariantsAndPriceView } from '../Views/SingleProduct/VariantsAndPriceView';
import { ProductWarehouseView } from '../Views/SingleProduct/ProductWarehouseView';
import { WithoutPriceView } from '../Views/SingleProduct/WithoutPriceView';

import { Product } from '../Models/ProductModel';

///=============================
export function VariantsAndPrice(props) {
	
	const [state, setState] = useState({
      productId: props.productId,
      price: '',
      variant_id: '',
      qty: 1
    });

    useEffect(() => {
    	getPdtAttributes();
	    VariantsForProduct();
	  }, []);

    const VariantsForProduct = () => {
    	ApiClass.VariantsForProduct(state).then(response => {
    		store.dispatch(StoreThisProductVariants(response));
    	})
    }

	  const getPdtAttributes = () => {

	    ApiClass.FrontEndThisProductAttributes({productId: state.productId}).then(response =>{
	      store.dispatch(StoreFrontEndThisProductAttributes(response));
	    })

	  }

	const updatePrice = (e) => {
		e.preventDefault();
		let variant = props.VariantsPerProduct;
		$('.variants select').each(function(){
			const name = $(this).attr('name');
			const val = $(this).val();
			variant = variant.filter(atr => atr.attribute_value[name] == val);

		})
		const price = (variant.length == 1) ? variant[0].price : '';
		const variant_id = (variant.length == 1) ? variant[0].id : '';
		setState(prevState => ({...prevState, price: price, variant_id: variant_id })) 
	}


	const ChangeQty = (e) => {
		const qty = e.target.value;
		setState(prevState => ({...prevState, qty: qty }))
	}

	const GetCartData = () => {
		ApiClass.GetCartData().then(data => {
    		store.dispatch(StoreCartData(data));
    	})
	}

	const AddToCart = (e) => {
		e.preventDefault();
		ApiClass.addToCart(state).then(response => {
			$('.alert.height').removeClass('height-hidden');
			function confirmCartAdding(){
				$('button[name=add-to-cart]').html('Dodany do kosza');
				$('button[name=add-to-cart]').addClass('disabled');
			}
			function removeConfirmCartAdding(){
				$('button[name=add-to-cart]').html('Dodaj do kosza');
				$('button[name=add-to-cart]').removeClass('disabled');
			}
			confirmCartAdding();
			setTimeout(removeConfirmCartAdding, 1000);
			GetCartData();
		})
	}


	let Variants = '';
	Variants = props.ProductAttributes && props.ProductAttributes.map(atr =>
	<VariantsItemView
		key={atr.id}
		id={atr.id}
		attribute={atr.attribute}
		values={atr.values}
		productId={state.productId}
		updatePrice={updatePrice}
	/>);

	//hide attribute when is simple product
	useEffect(()=>{
		SetFirstOptionSelected();
	}, [props])
	const SetFirstOptionSelected = () => {
		let variant = props.VariantsPerProduct;
		if(variant.length == 0){
			return
		}
		//let SimpleProduct = variant && variant[0]["attribute_value"]["Prosty produkt"];

		$('.variants select').each(function(){
			const firstOption =	$(this).find('option:nth-child(2)').val();
			$(this).val(firstOption);
		})
		let price = variant && variant[0]["price"];
		let variant_id = variant && variant[0]["id"];
		setState(prevState => ({...prevState, price: price, variant_id: variant_id }));
		

	}

	///hide price when all prices equals zero (usefull if want temporary stop selling)
	const AllPricesZero = () => {
		if(props.VariantsPerProduct.length != 0){
			const countOfUnzeroPrices = props.VariantsPerProduct && 
			props.VariantsPerProduct.filter(el => el.price != 0).length;

			if(countOfUnzeroPrices > 0){
				return false
			}
		}
		return true;
	}

	////=================
	const[showProductPrices, setShowProductPrices] = useState(null)
	useEffect( () => {
		Product.getShowProductPrices().then(data => {
			setShowProductPrices(data.value)
		})
	}, [])
	////=================


	if(props.VariantsPerProduct.length != 0 && AllPricesZero() == false && showProductPrices == true)
	{
		return <VariantsAndPriceView
			AddToCart={AddToCart}
			Variants={Variants}
			ChangeQty={ChangeQty}
			state={state}
			addToWishList={addToWishList}
		/>;
	} else {
		return <WithoutPriceView state={state} />
	}
	
}

const mapStateToProps = state => {
  return {
    ProductAttributes: state.FrontEndThisProductAttributes,
    VariantsPerProduct: state.VariantsPerProduct,
  }
}

const VariantsAndPriceWrapped = connect(mapStateToProps)(VariantsAndPrice);

if (document.getElementById('variants_and_price')) {

  const productId = document.getElementById('variants_and_price').getAttribute('data-props');

    ReactDOM.render(
    <Provider store={store}>
    <VariantsAndPriceWrapped productId={productId} />
    </Provider>, document.getElementById("variants_and_price"));
}
///=============================
///=============================
export function ProductWarehouse(props){

	const[data, setData] = useState({});

	useEffect(() => {
		Product.warehouse(props).then(response => {
			setData(response.data.data);
			if(response.data.data.length == 0){
				setData({'input_2': 'Przed zamówieniem prosimy o sprawdzanie dostępności'})
			}
		})
	}, [])

	return(<ProductWarehouseView data={data} />)
}

if (document.getElementById('product-warehouse')) {
  	const productId = document.getElementById('variants_and_price').getAttribute('data-props');
    ReactDOM.render(
    <ProductWarehouse productId={productId} />, document.getElementById("product-warehouse"));
}
///=============================