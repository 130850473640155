import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ApiClass } from '@/classes/Api';
import ProductPaginator from '../View/ProductPaginator';
import { store } from '@/classes/ReduxStore';
import { StoreCategoryProducts } from '@/actions';
import Products from '../View/Products';
import Spinner from '../View/Spinner';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { Product } from '../../Models/ProductModel';

function CategoryController(props){

	const [categoryId, setCategoryId] = useState(JSON.parse(props.dataCat).id || 777);

	const[path, setPath] = useState(null);
	const[search, setSearch] = useState(null);

	useEffect(() => {
		setPath(window.location.pathname);
		setSearch(window.location.search);
	}, [])


	useEffect(() => {
	if(path != null && search != null && window.location.search.indexOf('filters:') == -1 )
	{
		setIsLoading(true);
		ApiClass.getProducts({
			path: path,
			search:search }).then(resp =>{
			store.dispatch(StoreCategoryProducts(resp));
			setIsLoading(false);
		})
		return;
	}
	if(window.location.search.indexOf('filters:') == 1){
		setIsLoading(true);
		ApiClass.filter({
			categoryId:categoryId,
			search: search
			}).then(data =>{
			store.dispatch(StoreCategoryProducts(data.message));
			setIsLoading(false);
		})
	}

	}, [path, search])


	///update url without reload the page
	useEffect(() => {
		if(search !== null)
		{
			const newUrl = window.location.pathname + search;
			window.history.pushState("object or string", "Title", newUrl);
		}
	}, [path, search])

	const[IsLoading, setIsLoading] = useState(false);

	const[products, setProducts] = useState([]);

	const[paginator, setPaginator] = useState({
		current_page: null,
		last_page: null,
		prev_page_nr: null,
		next_page_nr: null
	});

	useEffect(() => {
		if(props.products)
		{
			const prev_page_nr = props.products.current_page - 1 == 0 ? null : props.products.current_page - 1;
			const next_page_nr = props.products.current_page + 1 > props.products.last_page ? null : props.products.current_page + 1;
			setPaginator({
				current_page: props.products.current_page,
				last_page: props.products.last_page,
				prev_page_nr: prev_page_nr,
				next_page_nr: next_page_nr
			})
		}
	}, [props.products])


	useEffect(() => {
		if(props.products)
		{
			setProducts(props.products.data);
		}
	}, [props.products]);


	////=================
	const[showProductPrices, setShowProductPrices] = useState(null)
	useEffect( () => {
		Product.getShowProductPrices().then(data => {
			setShowProductPrices(data.value)
		})
	}, [])
	////=================

	return(
		<Fragment>
	     <ProductPaginator
	      current_page={paginator.current_page}
	      last_page={paginator.last_page}
	      prev_page_nr={paginator.prev_page_nr}
	      next_page_nr={paginator.next_page_nr}
	      setSearch={setSearch}
	      />
	       
	      {(IsLoading) ? <Spinner />: ''}
			<Products products={products} showProductPrices={showProductPrices} />

			<ProductPaginator
		      current_page={paginator.current_page}
		      last_page={paginator.last_page}
		      prev_page_nr={paginator.prev_page_nr}
		      next_page_nr={paginator.next_page_nr}
		      setSearch={setSearch}
		      />
		</Fragment>
	)
}

const mapStateToProps = state => {
  return {
    products: state.ProductsPerCategory
  }
}

const CategoryControllerWrapped = connect(mapStateToProps)(CategoryController);

if (document.getElementById('ProductColumns')) {

	const dataCat = document.getElementById('filter').getAttribute('data-cat');

    ReactDOM.render(
    	<Provider store={store}>
    	<CategoryControllerWrapped dataCat={dataCat} />
    	</Provider>, document.getElementById("ProductColumns"));
}