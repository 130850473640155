import ThisProductAttributes from './ThisProductAttributes';
import AllProductAttributes from './AllProductAttributes';
import VariantsPerProduct from './VariantsPerProduct';
import ProductsPerCategory from './ProductsPerCategory';
import FrontEndThisProductAttributes from './FrontEndThisProductAttributes';
import UrlReducer from './UrlReducer';
import CartReducer from './CartReducer';
import IsLoadingReducer from './IsLoadingReducer';
import ActualFilters from './ActualFilters';
import CheckboxesCount from './CheckboxesCount';
import WishList from '../frontend/WishListReducer';
import DatabaseCustomers from './DatabaseCustomers';
import DistributorsList from './DistributorsList';
import ProductsList from './ProductsList';
import SingleProductInfo from './SingleProductInfo';
import CurrentCategoryId from './CurrentCategoryId';
import CurrentProductsSearchString from './CurrentProductsSearchString';
import InitProductsState from './InitProductsState';
import Categories from './Categories';

import { combineReducers } from 'redux';

	const allReducers = combineReducers ({
		ThisProductAttributes: ThisProductAttributes,
		AllProductAttributes: AllProductAttributes,
		VariantsPerProduct: VariantsPerProduct,

		ProductsPerCategory: ProductsPerCategory,
		FrontEndThisProductAttributes: FrontEndThisProductAttributes,
		Url: UrlReducer,
		CartData: CartReducer,
		IsLoading: IsLoadingReducer,
		ActualFilters: ActualFilters,
		CheckboxesCount:CheckboxesCount,
		WishList: WishList,
		DatabaseCustomers:DatabaseCustomers,
		DistributorsList: DistributorsList,
		ProductsList:ProductsList,
		SingleProductInfo: SingleProductInfo,
		CurrentCategoryId: CurrentCategoryId,
		CurrentProductsSearchString:CurrentProductsSearchString,
		InitProductsState: InitProductsState,
		Categories:Categories
	});

export default allReducers;