import React, { Fragment } from 'react';

export function WithoutPriceNotSubscribedView(props){
	
	const handleChange = props.handleChange;
	const subscribeToProduct = props.subscribeToProduct;
	const state = props.state;

	return(
		<Fragment>
		<p>Niestety nie wiemy jeszcze ceny tego produktu. Możesz kliknąć przycisk <i>"Powiadom mnie o cenie" </i>
		 . Jak tylko dostaniemy cenę, od razu dostaniesz od nas wiadomość na swój e-mail.</p>

		<p className="text-small mb-1"><b>NIE wykorzystamy</b> podany e-mail do kampanii reklamowych. E-mail jest potrzebny wyłącznie dla tego
		żeby wysłać info o cenie i dostępności produktu.</p>
		<hr />

		<form onSubmit={subscribeToProduct}>
			<div className="form-group col-md-8">
					<input type="email" value={state.email} className="form-control mb-1"
					name="email" onChange={handleChange} placeholder="twoj.email@gmail.com" />
					<button type="submit" name="subscribe"
					 className="btn btn-alt m-auto d-block alt">Powiadom mnie o cenie</button>
				
			</div>
		</form>
		</Fragment>
	)
}