import React, { useState, useEffect, Fragment } from 'react';
import WishListHeaderView from '../Views/WishListHeaderView';
import { WishList } from '../Models/WishList';
import { SaveWishList } from '@/actions';
import { store } from '@/classes/ReduxStore';

function WishListController(){

	const[items, setItems] = useState([]);

	useEffect(() => {
		WishList.getWishList().then(response => {
			setItems(response);
			store.dispatch(SaveWishList(response));
		})
	},[])
	
	return(
		<WishListHeaderView />
	)
}

export const addToWishList = (e) => {
	e.preventDefault();
	const product_Id = e.target.getAttribute('data-id');
	WishList.add({product_Id: product_Id}).then(response => {
		store.dispatch(SaveWishList(response));
	})
}

export const removeFromWishList = (e) => {
	e.preventDefault();
	const product_Id = e.target.getAttribute('data-id')
	WishList.remove({product_Id: product_Id}).then(response => {
		store.dispatch(SaveWishList(response));
	})
}

export default WishListController;

