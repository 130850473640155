const InitProductsState = (state = true, action) => {
	switch (action.type) {

		case 'INIT_PRODUCTS_STATE':
			return action.boolean;

		default:
			return state;
	}
};


export default InitProductsState;