const DistributorsList = (state = [], action) => {
	switch (action.type) {

		case 'DISTRIBUTORS_LIST':
			return action.array;

		default:
			return state;
	}
};


export default DistributorsList;