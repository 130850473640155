import React, { Fragment } from 'react';
import { store } from '../../../classes/ReduxStore';
import { connect } from 'react-redux';

function WishListHeaderView(props){

	const WishListCount = props.data && props.data.json.length;

	return(
			<Fragment>
			<li className="wishlist">
				<a href="/lista-zyczen">
					<span className="sm-cart-icon-wrap">
						<span className="icon">
							<span className="lnr lnr-heart">
							</span>
						</span>
						<span className={"sm-cart-count "+(WishListCount == 0 ? 'd-none': '')}>
						{WishListCount}
						</span>
					</span>
				</a>
			</li>
			</Fragment>
		)

}

const mapStateToProps = state => {
  return {
    data: state.WishList,
  }
}

const WishListHeaderViewWrapped = connect(mapStateToProps)(WishListHeaderView);

export default WishListHeaderViewWrapped;