import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { store } from '@/classes/ReduxStore';
import { connect, Provider } from 'react-redux';
import { ApiClass } from '@/classes/Api';
import MobileFilterViewTest from '../View/MobileFilterViewTest';
import { StoreCategoryProducts } from '@/actions';


function FilterControllerTest(props){

	///set initial data
	const [categoryId, setCategoryId] = useState(JSON.parse(props.dataCat).id || 777);
	const [filters, setFilters] = useState([]);
	const [filterValues, setFilterValues] = useState({});
	useEffect(() => {
		ApiClass.GetRelativeFilters({categoryId: categoryId}).then(response => {
    		setFilters(response);
    	});
	}, []);
	useEffect(() => {
		if(filters.length > 0)
		{
			ApiClass.getAllValuesForAllAttributesNames({filters: filters, categoryId: categoryId}).then(response => {
	    		setFilterValues(response);
	    	});
	    }
	}, [filters])
	///set initial data
	///==============================
	///set filter object
	const [filtersObject, setFiltersObject] = useState({});
	const checkFilterValue = (e) => {

		const FilterName = e.target.getAttribute('name');
		const FilterValue = e.target.getAttribute('value');
		let FilterValueArr = [ FilterValue ];


		setFiltersObject(prevState => (
			addDeleteValue(prevState),
			newValue(prevState),
			{...prevState}
		))

		const addDeleteValue = (prevState) => {
			if(prevState[FilterName] != null){
				if( filtersObject[FilterName] && filtersObject[FilterName].includes(FilterValue) )
				{
					///delete from array
					if(filtersObject[FilterName].length == 1)
					{
						///remove also key and value from object
						const { [FilterName]: remove, ...restOfObject } = prevState;
						setFiltersObject(restOfObject);
					}
					///if has many values
					if(filtersObject[FilterName].length > 1)
					{
						setFiltersObject(prevState => ({
							...prevState,
							[FilterName]:prevState[FilterName].filter(e => e !== FilterValue)
						}))
					}

				} else {
					///add to array
					setFiltersObject(prevState => ({
						...prevState,
						[FilterName]:[...prevState[FilterName], FilterValueArr[0]]
					}))
				}
			}
			return;
		}
		const newValue = (prevState) => {
			if(prevState[FilterName] == null){
				setFiltersObject(prevState => ({
					...prevState,
					[FilterName]:FilterValueArr
				}))
			}
			return;
		}
	}
	///if url exists need to set up object
	useEffect(() => {
		if(search.length > 0){
			const object = makeObjectFromAttrsInSearch();
			setFiltersObject(object);
		}		
	}, [])
	const makeOnlyFiltersFromSearch = () => {
		let onlyFilters = '';
		if(search.includes('filters:')){
			onlyFilters = search.split('filters:')[1];
		}
		return onlyFilters;
	}

	const makeObjectFromAttrsInSearch = () => {
		const onlyFilters = makeOnlyFiltersFromSearch();
		const array = onlyFilters.split(';');
		const ObjectWithFilters = {};
		array.map(el => {
			const filterArr = el.split('=');
			if(filterArr.length > 1 && filterArr[1].length > 0){
				ObjectWithFilters.[filterArr[0]] = filterArr[1].split(',');
			}
		})

		return ObjectWithFilters;
	}
	///if url exists need to set up object
	///set filter object
	///==============================
	///set filter string
	const [filtersString, setFiltersString] = useState('');
	useEffect(() =>{
		if( Object.keys(filtersObject).length > 0 )
		{	
			const part1 = 'filters:';
			const string = JSON.stringify(filtersObject);
			const cleanString = string && string.replaceAll('{', '').replaceAll('}', '')
			.replaceAll('"', '').replaceAll(':','=').replaceAll('=[','=').replaceAll('],',';').replaceAll(']','');

	    	setFiltersString(part1 + cleanString);
	    } else {
	    	setFiltersString('');
	    }

    }, [filtersObject]);
	///set filter string
	///==============================
	//set url
	const[search, setSearch] = useState(window.location.search);
	useEffect(() => {
		if(search.includes('?szukaj=') && filtersString == '')
		{	
			const searchQuery = search.split('szukaj=')[1].split('&')[0];
			setSearch(window.location.pathname+'?szukaj='+searchQuery);
			return
		}
		if(search.includes('?szukaj=') && filtersString && filtersString.length > 0)
		{	
			const divider = '&';
			const searchQuery = search.split('szukaj=')[1].split('&')[0];
			setSearch(window.location.pathname+'?szukaj='+searchQuery+divider+filtersString);
			return
		}
		if(search.includes('page=') && filtersString && filtersString.length > 0){

			return
		}
		const divider = filtersString && filtersString.length ? '?' : '';
		setSearch(window.location.pathname+divider+filtersString)
	}, [filtersString])
	useEffect(() => {
		window.history.pushState(null, null, search);
	}, [search])
	//set url
	///==============================
	//make api requests to backend
	const filtersData = search.split('?')[1];
	useEffect(() => {
		ApiClass.filter({
			categoryId:categoryId,
			search: filtersData
			}).then(data =>{
			//console.log(data)
			store.dispatch(StoreCategoryProducts(data.message));
			//props.setFilters(data.newFiltersAttrs);
			//props.setFilterValues(data.newFiltersValues);
		})
	}, [search])
	//make api requests to backend
	///==============================
	///additional functions
	const str_slug = (Text) =>
	{	
		const polishSymbols = {
				'ą' : 'a',
	            'ć' : 'c',
	            'ę' : 'e',
	            'ł' : 'l',
	            'ń' : 'n',
	            'ó' : 'o',
	            'ś' : 's',
	            'ź' : 'z',
	            'ż' : 'z'
		};
		
		let result = Text.toLowerCase()
		.replaceAll('ą', 'a').replaceAll('ć', 'c').replaceAll('ę', 'e').replaceAll('ł', 'l').
		replaceAll('ń', 'n').replaceAll('ó', 'o').replaceAll('ś', 's').replaceAll('ź', 'z').
		replaceAll('ż', 'z');

		result = result.replace(/[^a-z0-9. -]/g, "") // remove invalid chars
			    .replace(/\s+/g, "-") // collapse whitespace and replace by -
			    .replace(/-+/g, "-"); // collapse dashes

		if(result.charAt(result.length - 1) == '-'){//if last character is '-'
			result = result.substring(0,result.length - 1);///remove from string last '-'
		}

		return result;
	}
	const clearFilters = (e) =>{
		e.preventDefault();
		setFiltersObject({});
	}
	const makeArrFromAttrsInFilterObject = () => {
		const arrayForCheck = [];
		Object.keys(filtersObject).map(filterName => {
			filtersObject[filterName].map(filterValue => {
				arrayForCheck.push(filterName+'-'+filterValue)
			})
						
		})
		return arrayForCheck;
	}
	useEffect(() => {
		const arr = makeArrFromAttrsInFilterObject();
		arr.map(id=>{
			const checkbox = document.getElementById(id);
			if(checkbox != null){
				checkbox.checked = true;
			}
		})
	}, [filterValues]);
	const[checkboxesCount, setCheckboxesCount] = useState(0);
	useEffect(() => {
		const arr = makeArrFromAttrsInFilterObject();
		setCheckboxesCount(arr.length)
		if(arr.length == 0){
			const allValues = document.querySelector('.product-filters').querySelectorAll('input');
			Array.from(allValues).map(checkbox => {
				checkbox.checked = false;
			})
		}
		
	}, [filtersObject]);


	const makeOnlySearchQueryFromSearch = () => {
		let searchQuery = '';
		if(search.includes('szukaj=')){
			searchQuery = search.split('szukaj=')[1];
		}
		return searchQuery;
	}
	const getPageNrFromSearch = () => {
		let pageNr = '';
		if(search.includes('page=')){
			pageNr = search.split('page=')[1];
		}
		return pageNr;
	}
	///additional functions

	
	return(
		<MobileFilterViewTest
			filters={filters}
			filterValues={filterValues}
			str_slug={str_slug}
			checkFilterValue={checkFilterValue}
			clearFilters={clearFilters}
			checkboxesCount={checkboxesCount}
			total={props.products.total}
		/>
	)

}

const mapStateToProps = state => {
  return {
    products: state.ProductsPerCategory,
  }
}

const FilterControllerTestWrapped = connect(mapStateToProps)(FilterControllerTest);

if (document.getElementById('filter')) {

	const dataCat = document.getElementById('filter').getAttribute('data-cat');

    ReactDOM.render(
    	<Provider store={store}>
    	<FilterControllerTestWrapped dataCat={dataCat} />
    	</Provider>, document.getElementById("filter"));
}

