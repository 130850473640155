import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { store } from '@/classes/ReduxStore';
import { Provider, connect } from 'react-redux';
import SiteHeaderCartView from '../View/SiteHeaderCartView';
import WishListController from '../../Controllers/WishListController';

function SiteHeaderCart(props){

  ////styling cart
  const[justChangeCount, setJustChangeCount] = useState(false);
  useEffect(() => {
    setJustChangeCount(true)
    setTimeout(() => {
      setJustChangeCount(false)
    }, 1000);

  }, [props.data.CartTotal])



	return(
		<SiteHeaderCartView props={props}
    justChangeCount={justChangeCount}
    />
		)

}


const mapStateToProps = state => {
  return {
    data: state.CartData,
  }
}

const SiteHeaderCartWrapped = connect(mapStateToProps)(SiteHeaderCart);

if (document.getElementById('site-header-cart')) {

    ReactDOM.render(
    	<Provider store={store}>
      <WishListController />
    	<SiteHeaderCartWrapped />
    	</Provider>, document.getElementById("site-header-cart"));
}