class WishListClass
{

getWishList = async (data) => {
      const url = '/lista-zyczen/list';
      const {data: {message}, status} = await axios(url, data);
        //console.log(Posts);
      return message;
}

add = async (data) => {
      const url = '/lista-zyczen/add';
      const {data: {message}, status} = await axios.post(url, data);
        //console.log(Posts);
      return message;
}

remove = async (data) => {
      const url = '/lista-zyczen/remove';
      const {data: {message}, status} = await axios.post(url, data);
        //console.log(Posts);
      return message;
}

subscribe = async (data) => {
      const url = '/subscribe-to-product';
      const {data: {message_data}, status} = await axios.post(url, data);
        //console.log(Posts);
      return message_data;
}

isSubscribed = async (data) => {
      const url = '/is-subscribed';
      const {data: response, status} = await axios.post(url, data);
      //console.log(Posts);
      return response;
}

unSubscribe = async (data) => {
    const url = '/unsubscribe-from-product';
    const {data: response, status} = await axios.post(url, data);
        //console.log(Posts);
    return response;
}

}

export const WishList = new WishListClass();