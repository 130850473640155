import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Analytics } from '@/components/frontend/Models/AnalyticsModel';


export const CheckUrl = (props) => {

	const data_switch = props.data_switch;
	const product_id = props.product_id;
	///disable/enable tracking
	if(data_switch == 0){
		return null;
	}

	const href = window.location.href;
	//if user stay at less 10 seconds on product page save data
	if(href.includes('/produkt/')){
		setTimeout(function(){
			trackGuestUser();
		}, 10000);
	}

	const trackGuestUser = () => {
		Analytics.trackGuestUser({
			'product_id': product_id
		})
	}

	return null;
	
}



if (document.getElementById('checkUrl')) {

	const data_switch = document.querySelector('meta[name=analytics-switch]').getAttribute('content');
	const product_id = document.querySelector('input[name=product_id]').value;

    ReactDOM.render(<CheckUrl data_switch={data_switch} product_id={product_id} />, document.getElementById("checkUrl"));
}