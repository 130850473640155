import React, { Fragment } from 'react';

export function ProductWarehouseView(props){

	const data = props.data;
	
	return(
		<Fragment>
			<h6 className="m-0">{data.input_2}</h6>
			<br/>
			<p>{data.input_3}</p>
		</Fragment>
	)
}