import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { store } from '../../../classes/ReduxStore';
import { Provider, connect } from 'react-redux';
import { removeFromWishList } from '../Controllers/WishListController';

function WishListView(props){

	const items = props.data != null ? props.data.json : [];
   const remove = removeFromWishList;


if(items.length == 0){
    return (
      <Fragment>
      <div className="text-center">
      <h2>Dodaj do produkty ulubionych</h2>
      <p>Potem będziesz mógł <b>szybko</b> je znaleźć</p>

      <a href="/sklep" className="mainblack-button button">Do sklepu</a>
      </div>
      </Fragment>
     )
  }
	
return(
	<table className="shop_table cart" cellSpacing="0">
      <thead>
         <tr>
            <th className="product-remove">&nbsp;</th>
            <th className="product-thumbnail">&nbsp;</th>
            <th className="product-name">Produkt</th>
            <th className="product-price">Cena</th>
            <th className="product-price">&nbsp;</th>
         </tr>
      </thead>
      <tbody>
         {items.map((pdt, i) => ( 
         <tr key={i} className="woocommerce-cart-form__cart-item cart_item">
            <td className="product-remove">
               <a data-id={pdt.id} href="#" title="Usuń z listy" onClick={remove} className="remove">×</a>
            </td>
            <td className="product-thumbnail">
               <a href={'/produkt/'+pdt.slug}>
                 <img src={pdt.image} alt="" />
               </a>
            </td>
            <td className="product-name">
               <a title={pdt.title} href={'/produkt/'+pdt.slug}>
               {pdt.title}</a>
            </td>
            <td className="product-price" data-title="Price">
               <span className="woocommerce-Price-amount amount">
               {pdt.price} zł</span>
            </td>
            <td className="product-button">
               <a href={'/produkt/'+pdt.slug} className="mainblack-button button">Kup teraz</a>
            </td>
         </tr>
         ))}

      </tbody>
   </table>
)
}

const mapStateToProps = state => {
  return {
    data: state.WishList,
  }
}

const WishListViewWrapped = connect(mapStateToProps)(WishListView);


if (document.getElementById('wishlist')) {

    ReactDOM.render(
       <Provider store={store}>
       <WishListViewWrapped />
       </Provider>, document.getElementById("wishlist"));
}
