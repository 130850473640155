const VariantsPerProduct = (state = 0, action) => {
	switch (action.type) {

		case 'VARIANTS':
			return action.json;

		default:
			return state;
	}
};


export default VariantsPerProduct;