class AnalyticsModel
{

trackGuestUser = async (data) => {
    const url = '/analytics/showpdt';
    const {data: {message}, status} = await axios.post(url, data);
  }

}

export const Analytics = new AnalyticsModel();