const WishListReducer = (state = null, json) => {
	switch (json.type) {

		case 'WISHLIST':
			return json;

		default:
			return state;
	}
};


export default WishListReducer;