import React, { Fragment } from 'react';

function SiteHeaderCartView(props){

	const CartCount = props.props.data.CartCount;
	const justChangeCount = props.justChangeCount;

	return(
			<Fragment>
			<li className="">
				<a href="/koszyk" className="cart-contents">
				<span className="sm-cart-icon-wrap">
				<span className="icon">
				<span className="lnr lnr-cart"></span>
				</span>
				<span className={"sm-cart-count " + (props.justChangeCount == true ? 'increase' : '')
				+ (CartCount == 0 ? ' d-none': '')}>
				{CartCount}
				</span>
				</span>
				<span className="sm-cart-wrap">
				<span className="sm-cart-text">Koszyk</span>
				
				</span>
				</a>
				</li>
				<li>
				<div className="widget woocommerce widget_shopping_cart">
				<div className="widget_shopping_cart_content"></div>
				</div>
			</li>
			</Fragment>
		)

}

export default SiteHeaderCartView;