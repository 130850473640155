import React, { Fragment } from 'react';

export function VariantsAndPriceView(props){

	const AddToCart = props.AddToCart;
	const Variants = props.Variants;
	const ChangeQty = props.ChangeQty;
	const state = props.state;
	const addToWishList = props.addToWishList;

	return(
		<Fragment>
		<form onSubmit={AddToCart}>
			<div className="variants mt-1 mb-1">
				{Variants}
			</div>
			<div className="form-group">
			<label htmlFor="qty">Ilość</label>
			<input type="number" name="qty" onChange={ChangeQty} value={state.qty}
			 className="form-control" min="1" max="99" step="1" />
			</div>
			<p className="price mb-1">
				<span data-variant-id={state.variant_id} className={(state.price != 0) ? 'font-weight-bold' : 'color-default'}>
				{(state.price != 0) ? state.price + ' zł' : 'Wybierz wariant'}</span>
			</p>
			<div className="alert alert-success text-center height height-hidden" role="alert">
			 Produkt jest dodany do koszyka. Przejdź <a href="/koszyk">do koszyka</a>
			</div>
			<div className="d-flex justify-content-between">
			<button type="submit" name="add-to-cart"
			 className={'button alt w-75 '+(state.price != 0 ? '' : 'disabled')}>
			 Dodaj do kosza</button>
			 <button onClick={addToWishList} data-id={state.productId} title="Dodaj do listy życzeń" className='button alt w-20'>
			 <span data-id={state.productId} className="lnr lnr-heart">
			 </span>
			 </button>
			</div>
		</form>
		</Fragment>

	)

}