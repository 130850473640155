import React, { Fragment } from 'react';

export function WithoutPriceSubscribedView(props){
	
	const handleChange = props.handleChange;
	const unsubscribeProduct = props.unsubscribeProduct;
	const state = props.state;

	return(
		<Fragment>
		<p>Niestety nie wiemy jescze ceny tego produktu.</p>
		<p>Śledzisz już za ceną tego produktu. Nie musisz nic robić, i my damy znać tobie kiedy produkt pojawi się 
		w sprzedaży</p>
		<hr />

		<form onSubmit={unsubscribeProduct}>
			<div className="form-group col-md-8">
					<button type="submit" name="subscribe"
					 className="btn btn-alt m-auto d-block alt w-100">Zrezygnuj z powiadomień</button>
			</div>
		</form>
		</Fragment>
	)
}