const Categories = (state = [], action) => {
	switch (action.type) {

		case 'PRODUCT_CATEGORIES':
			return action.array;

		default:
			return state;
	}
};


export default Categories;