const CheckboxesCount = (state = 0, action) => {
	switch (action.type) {

		case 'COUNT':
			return action.number;

		default:
			return state;
	}
};


export default CheckboxesCount;