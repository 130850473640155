import React, { useState, useEffect, Fragment } from 'react';

export function VariantsItemView(props){


	const Values = () => {
	  let values = [];
	  let counter = 0;
	    for (const value in props.values) {
	      values.push(
	           <option key={counter++} value={props.values[value]}>{props.values[value]}</option>
	        )
	    }

	    return values;
	  }

	  if(props.attribute == 'Prosty produkt'){
	  	return '';
	  }


	return(
		<Fragment>
			<label htmlFor={props.attribute}>{props.attribute}</label>
			<select onChange={props.updatePrice} required="" className="form-control" name={props.attribute}>
				<option value="">Wybierz</option>
					{Values()}
			</select>
		</Fragment>

	)

}