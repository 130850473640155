const AllProductAttributes = (state = 0, action) => {
	switch (action.type) {

		case 'ATTRIBUTES_ALL':
			return action.json;

		default:
			return state;
	}
};


export default AllProductAttributes;